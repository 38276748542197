module.exports = [
  {
    name: 'juntos',
    logo: 'juntos.png',
    projects: [
      {
        logoURL: '',
        title: 'Project I',
        displayTitle: 'initial',
        displayImg: 'none',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel est a nunc dignissim venenatis vel at justo. Sed vel nibh facilisis, pretium metus eu, gravida tortor. Sed efficitur viverra posuere.',
        backgroundImageURL: require('../assets/responsibility/mock02.png'),
        backgroundColor: '#FF0000',
        colorReadMore: '#FF0000',
        link: 'Responsibility'
      },
      {
        logoURL: require('../assets/responsibility/rodateca.png'),
        title: '',
        displayTitle: 'none',
        displayImg: 'initial',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel est a nunc dignissim venenatis vel at justo. Sed vel nibh facilisis, pretium metus eu, gravida tortor. Sed efficitur viverra posuere.',
        backgroundImageURL: require('../assets/responsibility/mock03.png'),
        backgroundColor: '#51BFED',
        colorReadMore: '#51BFED',
        link: 'Home'
      },
      {
        logoURL: '',
        title: 'Project III',
        displayTitle: 'initial',
        displayImg: 'none',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel est a nunc dignissim venenatis vel at justo. Sed vel nibh facilisis, pretium metus eu, gravida tortor. Sed efficitur viverra posuere.',
        backgroundImageURL: require('../assets/responsibility/mock01.png'),
        backgroundColor: '#F9BB14',
        colorReadMore: '#F9BB14',
        link: 'Responsibility'
      }
    ]
  }
]
