<template>
  <div id="responsibilities">
    <div class="responsibility" v-for="(responsibility, index) in ListResponsibilities" :key="`${responsibility.name}-${index}`">
      <list-item
        v-for="(project, idx) in responsibility.projects"
        :key="`${project.title}${idx}`"
        :logo="project.logoURL"
        :title="project.title"
        :description="project.description"
        :backgroundColor="project.backgroundColor"
        :display-img="project.displayImg"
        :display-title="project.displayTitle"
        :backgroundImage="project.backgroundImageURL"
        :color-read-more="project.colorReadMore"
        :link-to="project.link"
      />
    </div>
  </div>
</template>

<script>
import ListItem from '@/components/Responsibility/ListItem'
import ListResponsibilities from '@/utils/responsibility-itens.js'
export default {
  name: 'Responsibility',
  components: {
    ListItem
  },
  computed: {
    ListResponsibilities () {
      return ListResponsibilities
    }
  }
}
</script>

<style lang="scss" scoped>
  #responsibilities {
    display: flex;
    height: 100vh;
    width: 100vw;

    .responsibility {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
</style>
