<template>
  <button @click="goTo"  class="btn">
    <!-- <img src="@/assets/plu.png" alt="" srcset="" /> -->
    <span :style="{color:color}" class="plus icon-plus" :class="{}"></span>
    <span class="btn-see-more">{{ $t("app.readMore") }}</span>
  </button>
</template>

<script>
import router from '@/router'
export default {
  name: 'ReadMore',
  methods: {
    goTo () {
      if (this.color === '#51BFED') { router.push({ name: 'ResponsibilityView' }) } else { router.push({ name: 'Responsibility' }) }
    }
  },
  data () {
    return {
      ddd: ''
    }
  },
  props: {
    changeState: {
      required: true
    },
    link: {
      type: String
    },
    color: {
      type: String
    },
    intl: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.btn:hover {
  cursor: pointer;
}
.btn {
  margin: 25px 0;
  border: 1px solid #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  width: 30%;
  text-decoration: none;
  outline: none;

  .plus {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    font-size: 7pt;
    background: #fff;
    border-radius: 50%;
    margin-right: 4px;
  }
  .plus.icon-plus{
   }
  span {
    font-size: 14px;
    color: #fff;
    // padding:0 0 13px 5px;
  }
}
</style>
