<template>
  <div
    class="list-item"
    :style="{ 'background-color': backgroundColor }"
  >
    <div class="shadow" :style="[{'background-image': `url(${backgroundImage})` }]">
      <div class="container">
          <div class="project">
              <h1 :style="{'display':displayTitle}">{{title}}</h1>
              <img :style="{'display':displayImg}" :src="logo" alt="" srcset="">
          </div>
          <div class="wrapper">
            <h1>Lorem, ipsum dolo</h1>
            <p>{{description}}</p>
            <read-more :link="LinkTo" :color="colorReadMore" :changeState="changeState" intl="ler mais"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReadMore from '@/components/shared/Read-more2'
export default {
  name: 'ResponsibilityListItem',
  components: {
    'read-more': ReadMore
  },
  props: {
    logo: {
      required: true,
      type: String
    },
    displayTitle: {
      type: String
    },
    displayImg: {
      type: String
    },
    linkTo: {
      type: String
    },
    title: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String,
      minLength: 20
    },
    backgroundColor: {
      required: true,
      type: String
    },
    colorReadMore: {
      type: String
    },
    backgroundImage: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.shadow {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.container{
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100%;
  .project{
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1{
      color: #fff;
      text-transform: uppercase;
      font-size: 45px;
      padding-bottom: 100px;
    }
    img{
      width: 300px;
      padding-bottom: 100px;
    }
  }
  .wrapper{
    position: absolute;
    bottom: 50px;
    width: 100%;
    padding-left: 45px;
    h1{
      color: #fff;
      text-transform: uppercase;
      font-size: 25px;
      text-align: start;
    }
    p{
      padding-top: 10px;
      max-width: 400px;
      color: #fff;
      font-size: 16px;
    }
  }
}
.list-item {
  height: 100%;
  width: 100%;
  flex: 1;
}
</style>
